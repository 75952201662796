<template>
  <v-card outlined class="pa-4" min-width="100%">
    <v-row justify="end">
      <v-col cols="12" md="5" order="last">
        <p class="">
          Due Fee:
          <strong>{{ this.$store.getters["payment/total_amount"] }} Taka</strong>
        </p>
        <p class="">
          Bank Fee:
          <strong>{{
              (this.$store.getters["payment/total_amount"] * 1.5) / 100
          }}
            Taka</strong>
        </p>
        <p class="">
          Total Payable:
          <strong>{{
              (
                this.$store.getters["payment/total_amount"] +
                (this.$store.getters["payment/total_amount"] * 1.5) / 100
              ).toFixed(2)
          }}
            Taka</strong>
        </p>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn width="100%" color="primary" @click="pay">Pay Now</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import aamarpay from "./aamarpay.vue";
export default {
  components: { aamarpay },
  data() {
    return {
      dialog: false,
      valid: true,
      name: "",
      payment_url: "",
    };
  },
  methods: {
    pay() {
      this.$store.dispatch("payment/pay").then((res) => {
        this.payment_url = res.payment_url;
      }).then(() => {
        const popup = window.open(this.payment_url, "payment",
          "width=600,height=700,top=1200,left=650, toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,");
        const interval = setInterval(() => {
          if (popup.closed) {
            clearInterval(interval);
            this.$store.dispatch("payment/loadFees");
            this.$store.dispatch("payment/fetchPaymentHistory");
          }
        }, 1000);
      });
    },
    closeDialog: function () {
      this.dialog = false;
    },
  },
};
</script>

<style>

</style>
